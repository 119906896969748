/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import '../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';
html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

app-shoutbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

app-header {
  position: fixed;
  top: 0px; // 70
  width: 100%;
  z-index: 9999999;
}

app-footer input:-webkit-autofill,
app-footer input:-webkit-autofill:hover,
app-footer input:-webkit-autofill:focus,
app-footer input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #ffffff !important;
}

app-signin input:-webkit-autofill,
app-signin input:-webkit-autofill:hover,
app-signin input:-webkit-autofill:focus,
app-signin input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #ffffff !important;
}

app-signin .mat-mdc-input-element {
  color: #fff !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}

.cdk-overlay-container{
  z-index: 99999999999 !important;
}



/*
@font-face {
  font-family: "RobotoBlack";
  src: local("RobotoBlack"),
    url(/assets/fonts/Roboto/RobotoBlack.ttf) format("truetype");
    font-display: swap;
    unicode-range: U+0-2FFF,U+A500-FFFF;
  }
  */

@font-face {
  font-family: "NoctisRegular";
  src: local("NoctisRegular"),
    url(/assets/fonts/Noctis/NoctisRegular.ttf) format("truetype");
  font-display: swap;

}

@font-face {
  font-family: "RobotoBlack";
  src: local("RobotoBlack"),
    url(/assets/fonts/Roboto/RobotoBlack.ttf) format("truetype");
  font-display: swap;
  unicode-range: U+0-2FFF, U+A500-FFFF;
}

@font-face {
  font-family: "RobotoBlackItalic";
  src: local("RobotoBlackItalic"),
    url(/assets/fonts/Roboto/RobotoBlackItalic.ttf) format("truetype");
  font-display: swap;
  unicode-range: U+0-2FFF, U+A500-FFFF;
}

@font-face {
  font-family: "RobotoBold";
  src: local("RobotoBold"),
    url(/assets/fonts/Roboto/RobotoBold.ttf) format("truetype");
  font-display: swap;
  unicode-range: U+0-2FFF, U+A500-FFFF;
}

@font-face {
  font-family: "RobotoBoldItalic";
  src: local("RobotoBoldItalic"),
    url(/assets/fonts/Roboto/RobotoBoldItalic.ttf) format("truetype");
  font-display: swap;
  unicode-range: U+0-2FFF, U+A500-FFFF;
}

@font-face {
  font-family: "RobotoItalic";
  src: local("RobotoItalic"),
    url(/assets/fonts/Roboto/RobotoItalic.ttf) format("truetype");
  font-display: swap;
  unicode-range: U+0-2FFF, U+A500-FFFF;
}

@font-face {
  font-family: "RobotoLight";
  src: local("RobotoLight"),
    url(/assets/fonts/Roboto/RobotoLight.ttf) format("truetype");
  font-display: swap;
  unicode-range: U+0-2FFF, U+A500-FFFF;
}

@font-face {
  font-family: "RobotoLightItalic";
  src: local("RobotoLightItalic"),
    url(/assets/fonts/Roboto/RobotoLightItalic.ttf) format("truetype");
  font-display: swap;
  unicode-range: U+0-2FFF, U+A500-FFFF;
}

@font-face {
  font-family: "RobotoMedium";
  src: local("RobotoMedium"),
    url(/assets/fonts/Roboto/RobotoMedium.ttf) format("truetype");
  font-display: swap;
  unicode-range: U+0-2FFF, U+A500-FFFF;
}

@font-face {
  font-family: "RobotoMediumItalic";
  src: local("RobotoMediumItalic"),
    url(/assets/fonts/Roboto/RobotoMediumItalic.ttf) format("truetype");
  font-display: swap;
  unicode-range: U+0-2FFF, U+A500-FFFF;
}

@font-face {
  font-family: "RobotoRegular";
  src: local("RobotoRegular"),
    url(/assets/fonts/Roboto/RobotoRegular.ttf) format("truetype");
  font-display: swap;
  unicode-range: U+0-2FFF, U+A500-FFFF;
}

@font-face {
  font-family: "RobotoThin";
  src: local("RobotoThin"),
    url(/assets/fonts/Roboto/RobotoThin.ttf) format("truetype");
  font-display: swap;
  unicode-range: U+0-2FFF, U+A500-FFFF;
}

@font-face {
  font-family: "RobotoThinItalic";
  src: local("RobotoThinItalic"),
    url(/assets/fonts/Roboto/RobotoThinItalic.ttf) format("truetype");
  font-display: swap;
  unicode-range: U+0-2FFF, U+A500-FFFF;
}

h1 {
  font-family: "NoctisRegular", Roboto, Helvetica, sans-serif;
  font-size: 70px;
}

h2 {
  font-family: "NoctisRegular", Roboto, Helvetica, sans-serif;
  font-size: 34px;
}

h3 {
  font-family: "RobotoMedium", Roboto, Helvetica, sans-serif;
  font-size: 20px;
}

h4 {
  font-family: "RobotoRegular", Roboto, Helvetica, sans-serif;
  font-size: 18px;
}

p {
  font-family: "RobotoRegular", Roboto, Helvetica, sans-serif;
  font-size: 16px;
}

.container {
  width: 100%;
}

.error-snackbar {
  background-color: #ce4f4f !important;
  text-align: center !important;
  color: #ffffff !important;
  --mdc-snackbar-container-color: #ce4f4f;
  --mat-mdc-snack-bar-button-color: white;
  .mdc-button__label {
    color: white !important;
  }
}

.success-snackbar {
  background-color: #4ec31a !important;
  text-align: center !important;
  color: #ffffff !important;
  --mdc-snackbar-container-color: #4ec31a;
  --mat-mdc-snack-bar-button-color: white;
  .mdc-button__label {
    color: white !important;
  }
}

.mat-simple-snack-bar-content {
  width: 100% !important;
}

.info-snackbar {
  background-color: #fff !important;
  text-align: center !important;
  color: #434343 !important;
  --mdc-snackbar-container-color: #fff;
  --mat-mdc-snack-bar-button-color: #434343 !important;
  .mat-mdc-snack-bar-label {
    color: #434343 !important;
  }
  .mdc-button__label {
    color: #000 !important;
  }
}

::-webkit-scrollbar {
  width: 10px;
  overflow-y: auto;
  background: grey;
  box-shadow: inset 0 0 4px #707070;
}

::-webkit-scrollbar-thumb {
  background: #f5f5f5;
  border-radius: 10px;
}

.mat-dialog-container {
  background: #ffffff;
  color: #000000;
  border-radius: 20px !important;
  background-color: #fff !important;
  color: initial !important;
}

.full-row {
  width: 100%;
}

.row-layout-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.row-layout-start {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.column-layout-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.column-layout-start-justify-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.column-layout-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.column-layout-space-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.column-layout-space-evenly {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.column-layout-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.column-layout-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.column-layout-center-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.column-layout-center-end {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: center;
}

.column-layout-start-end {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
}

.column-layout-start-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.row-layout-space-around {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}

.row-layout-center-start {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.row-layout-center-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.row-layout-space-between-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.row-layout-space-between-around {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.row-layout-space-between-start {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.row-layout-start-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}



.dialog-width {
  max-width: 90vw !important;
  width: 100% !important;
}

.what-is-ai-dialog-panel {
  max-width: 90vw !important;
  height: 85vh !important;
}

@media screen and (max-width: 830px) {
  app-header {
    top: 0;// 44
  }
}

@media screen and (max-width: 576px) {
  .what-is-ai-dialog-panel {
    max-width: 90vw !important;
    height: 90vh !important;
  }
}

@media screen and (max-width: 440px) {
  .what-is-ai-dialog-panel {
    max-width: 90vw !important;
    height: 95vh !important;
  }
}

.row-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.mat-body,
.mat-body-1,
.mat-typography {
  font: 400 16px/20px "RobotoRegular", Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black !important;
}

.mat-tab-header-pagination-chevron {
  border-color: black !important;
}

.mat-select-value {
  color: #696969 !important;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #696969 !important;
}

.mat-select-value {
  color: #696969 !important;
}

.mat-select-arrow {
  color: #696969 !important;
}


::ng-deep.material-icons{
  font-family: 'Material Icons' !important;
}

.mat-icon-info {
  margin-right: 6px;
  cursor: pointer;
}

.mat-tooltip-info {
 // font-size: 14px !important;
}


  $custom-main-color: rgba(0, 0, 0, .6);
  $custom-label-color: rgba(0, 0, 0, .6);

  // mat-icon-stepper selected color change
  .mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
     background-color: $custom-main-color !important;
  }

  // input outline color
  .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline {
     color: $custom-main-color !important;
  }

  // mat-input focused color
  .mat-mdc-form-field-appearance-outline.mat-focused .mat-mdc-form-field-outline-thick {
     color: $custom-main-color !important;
  }

  // mat-input error outline color
  .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
     //color: $custom-main-color !important;
     //opacity: 0.8 !important;
  }
  

  // mat-input caret color
  .mat-input-element {
     caret-color: $custom-main-color !important;
  }

  // mat-input error caret color
  .mat-form-field-invalid .mat-input-element, .mat-warn .mat-input-element {
     caret-color: $custom-main-color !important;
  }

  // mat-label normal state style
  .mat-form-field-label {
     color: $custom-label-color !important;
  }

  // mat-label focused style
  .mat-form-field.mat-focused .mat-form-field-label {
     color: $custom-main-color !important;
  }

  // mat-label error style
  .mat-form-field.mat-form-field-invalid .mat-form-field-label {
     color: $custom-main-color !important;
  }

  .mdc-notched-outline {
    color: $custom-main-color !important;
  }

  

  .mat-mdc-checkbox-ripple, .mdc-checkbox__ripple {
    top: 2px !important;
    left: 2px !important;

  }

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  background-color:  #c5b595 !important;
  border-color:  #c5b595 !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color:  #c5b595 !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
  background-color:  #c5b595 !important;
  border-color:  #c5b595 !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color:  #c5b595 !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
 color: inherit !important;
}

.mat-pseudo-checkbox{ display: none !important; }

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
 color: inherit !important;
 
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:hover {
 
  -webkit-text-fill-color: #fff !important;
 // -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
  
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
   // -webkit-box-shadow: 0 0 0px 1000px white inset !important;
   // box-shadow: 0 0 0px 1000px white inset !important;
    transition: background-color 5000s ease-in-out 0s !important;
    transition-delay: background-color 9999s !important;
    color: #000000 !important;
    -webkit-text-fill-color: #000000 !important;
}

button:disabled {
  opacity: .7 !important;
  cursor: auto !important;
}

